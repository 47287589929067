.main-modal-container .ant-modal-content {
  padding: 0 18px;
  border-radius: 16px;
  min-width: 630px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.main-modal-container.full-screen .ant-modal-content {
  padding: 0 8px 0 24px;
  gap: 8px;
}

.main-modal-container .ant-modal-body {
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.main-modal-container .ant-modal-body::-webkit-scrollbar {
  width: 12px;
}

.main-modal-container .ant-modal-body::-webkit-scrollbar-track {
  background: #F0F2F4;
  border-radius: 100px;
}

.main-modal-container .ant-modal-body::-webkit-scrollbar-thumb {
  background: #B6BFC9;
  border-radius: 100px;
}

.main-modal-container .ant-modal-header {
  margin-bottom: 0;
  position: relative;
  width: calc(100% + 36px);
  left: -18px;
}

.main-modal-container.full-screen .ant-modal-header {
  margin-bottom: 8px;
  width: calc(100% + 32px);
  left: -24px;
}

.main-modal-container .ant-modal-footer {
  margin-top: auto;
  position: relative;
  width: calc(100% + 36px);
  left: -18px;
}

.main-modal-container.full-screen .ant-modal-footer {
  width: calc(100% + 32px);
  left: -24px;
}

@media screen and (max-width: 750px) {
  .main-modal-container .ant-modal-content {
    min-width: 99%;
  }
}
