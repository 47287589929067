@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    font-family: 'Roboto', system-ui, sans-serif;
  }
}

* {
  -webkit-tap-highlight-color: transparent;
}

/* Custom Scrollbar Styles */
/* For Chrome, Safari, and Opera */
::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-track {
  background: #F0F2F4;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  background: #B6BFC9;
  border-radius: 100px;
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #B6BFC9 #F0F2F4;
}

/* Custom Scrollbar Styles */
/* For Chrome, Safari, and Opera */
.scrollable-content::-webkit-scrollbar {
  width: 9px;
}

.scrollable-content::-webkit-scrollbar-track {
  background: #F0F2F4;
  border-radius: 100px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background: #B6BFC9;
  border-radius: 100px;
}

/* For Firefox */
.scrollable-content {
  scrollbar-width: thin;
  scrollbar-color: #B6BFC9 #F0F2F4;
}

/*
 Global/reset css styles, imported from:
 'https://dora.michalostamou.me/global.css'
 @see https://github.com/dora-micha/global-css
*/

/*
  Component styles
*/
.stepProgressBar {
  --stepProgressBar-space-unit: 4px;
  --stepProgressBar-indicator-size: 16px;
  --stepProgressBar-font-size: 14px;
  --stepProgressBar-step-size: 80px;
  --stepProgressBar-step-line-size: 2px;
  --stepProgressBar-color-primary: #259DFF;
  --stepProgressBar-color-secondary: #d1d5db;
  --stepProgressBar-color-neutral: #fff;
  --stepProgressBar-color-neutral-400: #a3a3a3;
  --stepProgressBar-color-neutral-800: #262626;
  display: flex;
  width: 100%;
}

.stepProgressBar__step {
  display: flex;
}

.stepProgressBar__step:not(:first-child) {
  width: 100%;
}

/* --- button ---- */
.stepProgressBar__step__button {
  align-items: center;
  color: var(--stepProgressBar-color-neutral-400);
  display: flex;
  font-size: var(--stepProgressBar-font-size);
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
  transition: color 0.2s ease-in;
  width: var(--stepProgressBar-step-size);
}

.stepProgressBar__step--current .stepProgressBar__step__button {
  color: var(--stepProgressBar-color-neutral-800);
}

.stepProgressBar__step__button__indicator {
  align-items: center;
  background-color: var(--stepProgressBar-color-secondary);
  border: calc(var(--stepProgressBar-indicator-size) / 3.5) solid var(--stepProgressBar-color-neutral);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  height: var(--stepProgressBar-indicator-size);
  transition: background-color 0.3s ease-in;
  width: var(--stepProgressBar-indicator-size);
}

.stepProgressBar__step--current .stepProgressBar__step__button__indicator {
  background-color: var(--stepProgressBar-color-neutral);
  border: calc(var(--stepProgressBar-indicator-size) / 8) solid var(--stepProgressBar-color-primary);
  transition: all 0.3s ease-in;
}

.stepProgressBar__step--completed .stepProgressBar__step__button__indicator {
  border: 0;
  transition: all 0.3s ease-in;
}

.stepProgressBar__step:not(.stepProgressBar__step--current).stepProgressBar__step--navigable .stepProgressBar__step__button__indicator {
  background-color: var(--stepProgressBar-color-primary);
  transition: all 0.3s ease-in;
}

.stepProgressBar__step__button__indicator__icon-completed {
  fill: transparent;
}

.stepProgressBar__step--completed .stepProgressBar__step__button__indicator__icon-completed {
  animation: fillColorChange 0.2s ease-in 0.3s forwards;
}

.stepProgressBar__step__button__label {
  margin-top: var(--stepProgressBar-space-unit);
}

/* --- button states ---- */

@supports selector(:hover) {
  .stepProgressBar__step__button:hover {
    color: var(--stepProgressBar-color-primary);
  }

  .stepProgressBar__step:not(.stepProgressBar__step--current) .stepProgressBar__step__button:hover .stepProgressBar__step__button__indicator {
    background-color: var(--stepProgressBar-color-primary);
  }
}

.stepProgressBar__step__button:focus-visible {
  border-radius: 4px;
  outline: var(--stepProgressBar-color-primary) solid 2px;
}

@supports not selector(:focus-visible) {

  /* fallback for browsers without focus-visible support */
  .stepProgressBar__step__button:focus {
    border-radius: 4px;
    outline: var(--stepProgressBar-color-primary) solid 2px;
  }
}

/* --- line ---- */
.stepProgressBar__step__line {
  background-color: var(--stepProgressBar-color-secondary);
  height: var(--stepProgressBar-step-line-size);
  margin-top: calc(var(--stepProgressBar-indicator-size) / 2 - var(--stepProgressBar-step-line-size) / 2);
  margin-left: calc(var(--stepProgressBar-step-size) / 2 * -1);
  margin-right: calc(var(--stepProgressBar-step-size) /2 * -1);
  transition: background-color 0.3s ease-in;
  width: 100%;
}

.stepProgressBar__step--navigable .stepProgressBar__step__line {
  background-color: var(--stepProgressBar-color-primary);
  transition: background-color 0.2s ease-in;
}

/* --- other ---- */

@keyframes fillColorChange {
  0% {
    fill: var(--stepProgressBar-color-primary);
  }

  100% {
    fill: var(--stepProgressBar-color-neutral)
  }
}

/* This media query is based on step-size * 5,
    if you change the value of it update it here too
 */
@media (max-width: 400px) {
  .stepProgressBar__step__button {
    width: auto;
  }

  .stepProgressBar__step__line {
    margin-left: 0;
    margin-right: 0;
  }

  .stepProgressBar__step__button__label {
    display: none;
  }
}

/* Pen styles (ignore these styles) */
.container {
  margin: 0 auto;
  max-width: 1000px;
  min-width: 320px;
  padding: 100px 24px;
  width: 100%;
}

.code {
  letter-spacing: 0.5em;
}

.code::placeholder {
  font-size: 80px;
  letter-spacing: 0.5em;
  text-align: center;
}

@media (orientation: portriet) {
  .register {
    min-height: 75vh;
  }
}

/* for ANTD Input */
.ant-input,
.ant-input:hover {
  border-color: #B6BFC9 !important;
}

.ant-input.ant-input-status-error {
  border-color: #C80100 !important;
}

.ant-select.custom-error .ant-select-selector {
  border-color: #C80100 !important;
}

/* login tabs */
.login-page .ant-tabs-nav-list {
  width: 100%;
  justify-content: center;
}

.login-page .ant-tabs-tab {
  width: 52%;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 18px;
}

.login-page .ant-tabs-tab+.ant-tabs-tab {
  margin: 0;
}

.login-page .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn p {
  color: #111417;
}

.login-page .ant-tabs-nav {
  margin-bottom: 40px;
}

.login-page .ant-tabs-nav::before {
  border-bottom-color: #B6BFC9;
}

.login-page .ant-tabs-nav .ant-tabs-ink-bar {
  height: 6px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.login-page .ant-tabs-ink-bar {
  background-color: #111417;
}

/* */

/* RoommateInfoPage Steps+List */
.ant-steps.ant-steps-vertical>.ant-steps-item .ant-steps-item-icon {
  margin-inline-end: 20px;
}

.ant-steps.ant-steps-vertical>.ant-steps-item .ant-steps-item-icon .anticon {
  vertical-align: 0;
  color: #259DFF;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #259DFF;
  border-color: #259DFF;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #F1F5F9;
  border-color: #F1F5F9;
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: #259DFF;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)>.ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
  border-color: #0080E8;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)>.ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
  color: #0080E8;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)>.ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon .anticon {
  color: #0080E8;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button']:hover .ant-steps-item-title {
  color: #0080E8;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button']:hover .ant-steps-item-description {
  color: #259DFF;
}

.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-avatar {
  margin-inline-end: 12px;
}

.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-description {
  color: #3D4752;
}

.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
  color: #3D4752;
}

/* Fot ANT select clear button */
.ant-select-clear {
  width: 24px !important;
  height: 24px !important;
  margin-top: -12px !important;
}
